import React from 'react';
import { SidebarPortal } from '@plone/volto/components';

import ThumbnailSidebar from './ThumbnailsSidebar';
import View from './BlockView';


const Edit = (props) => {
  const { data, onChangeBlock, block, selected } = props;

  return (
    <div className={'block thumbnails'}>
      <View {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <ThumbnailSidebar data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
