import React from 'react';
import NavItem from '@plone/volto/components/theme/Navigation/NavItem';

const NavItems = ({ items, lang }) => {
  const ds = {
    url: '/datenschutzerklaerung.pdf/@@download/file',
    title: 'Datenschutz',
  };
  return (
    <>
      {items.map((item) => (
        <NavItem item={item} lang={lang} key={item.url} />
      ))}
      <a
        href='/datenschutzerklaerung.pdf/@@download/file'
        className="item"
        rel="noopener noreferrer"
        target='_blank'
      >Datenschutz</a>
    </>
  );
};

export default NavItems;
