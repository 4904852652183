import icon from '@plone/volto/icons/slider.svg';
import BannerBlockEdit from './Banner/BlockEdit';
import BannerBlockView from './Banner/BlockView';
import ThumbnailBlockEdit from './Thumbnails/BlockEdit';
import ThumbnailBlockView from './Thumbnails/BlockView';
export default function applyConfig(config) {

  config.blocks.blocksConfig.thumbnails = {
    id: 'thumbnails',
    title: 'Thumbnails',
    edit: ThumbnailBlockEdit,
    view: ThumbnailBlockView,
    icon: icon,
    group: 'common',
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.banner = {
    id: 'banner',
    title: 'Banner',
    edit: BannerBlockEdit,
    view: BannerBlockView,
    icon: icon,
    group: 'common',
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  return config;
}
