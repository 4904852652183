import React, { Component } from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';
import { Icon, PreviewImage } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';


class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openIndex: undefined,
    };

    this.closeModal = this.closeModal.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.prevImage = this.prevImage.bind(this);
  }

  closeModal() {
    this.setState({
      openIndex: -1,
    });
  }

  nextImage() {
    const openIndex =
      (this.state.openIndex + 1) % this.props.content.items.length;
    this.setState({
      openIndex,
    });
  }

  prevImage() {
    const openIndex =
      (this.state.openIndex - 1) % this.props.content.items.length;
    this.setState({
      openIndex,
    });
  }

  render() {
    const { data} = this.props;
    return (
        <div className="block banner">

          <div className="allslides">
            {data.columns && data.columns.length &&
              [0,1,2,3,4,5,6].map((k, index) => (
                  <figure key={data.columns[index % data.columns.length]['@id']}>
                      <img
                        key={data.columns[index % data.columns.length]['@id']}
                        src={flattenToAppURL(`${data.columns[index % data.columns.length].href?.[0]['@id']}/@@images/image/great`)}
                        title={data.columns[index % data.columns.length].title}
                      />
                  </figure>
              ))}
              {data.columns && data.columns.length &&
                <figure key={data.columns[0]['@id']}>
                    <img
                      key={data.columns[0]['@id']}
                      src={flattenToAppURL(`${data.columns[0].href?.[0]['@id']}/@@images/image/large`)}
                      title={data.columns[0].title}
                    />
                </figure>
              }
          </div>

          <div className="welcome">
            <p>Willkommen bei</p>
            <h3>holzkonzept</h3>
            <h3>freiburg</h3>
          </div>

        </div>
    );
  }
};

export default View;
