import React, { Component } from 'react';
import { Button, Grid, Image, Modal } from 'semantic-ui-react';
import { Icon, PreviewImage } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import backSVG from '@plone/volto/icons/back.svg';


class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openIndex: undefined,
    };

    this.closeModal = this.closeModal.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.prevImage = this.prevImage.bind(this);
  }

  closeModal() {
    this.setState({
      openIndex: -1,
    });
  }

  nextImage() {
    const openIndex =
      (this.state.openIndex + 1) % this.props.data.columns.length;
    this.setState({
      openIndex,
    });
  }

  prevImage() {
    const openIndex =
      (this.state.openIndex - 1) % this.props.data.columns.length;
    this.setState({
      openIndex,
    });
  }

  render() {
    const { data} = this.props;
    return (
      <div className="block thumbnails">

        <Grid className="container" columns="12">
        {data.columns &&
          data.columns.map((item, index) => (
          <Modal
            key={item['@id']}
            className="gallery"
            onClose={this.closeModal}
            open={this.state.openIndex === index}
            trigger={
              <Grid.Column mobile="3" computer="1">
              <img
                className="ui rounded image"
                key={item['@id']}
                src={flattenToAppURL(`${item.href?.[0]['@id']}/@@images/image/thumb`)}
                title={item.title}
                onClick={() => {
                  this.setState({
                    openIndex: index,
                  });
                }}
              />
              </Grid.Column>
            }
          >
            <Modal.Content image>
              <Grid
                  key={item['@id']}
                  columns="12">
                <Grid.Column computer="1" mobile="2">
                  <Button
                    className="gallery noborder"
                    onClick={this.prevImage}
                    style={{ margin: 0 }}
                    disabled={index == 0}
                  >
                    <Icon
                      name={backSVG}
                      className="circled"
                      fitted="1"
                      style={{ margin: 0 }}
                    />
                  </Button>
                </Grid.Column>
                <Grid.Column computer="10" mobile="8">
                  <Image
                    key={item['@id']}
                    src={flattenToAppURL(`${item.href?.[0]['@id']}/@@images/image/large`)}
                    title={item.title}
                    fluid="1"
                  />
                </Grid.Column>
                <Grid.Column computer="1" mobile="2">
                  <Button
                    className="gallery noborder"
                    onClick={this.nextImage}
                    style={{ margin: 0 }}
                  >
                    <Icon
                      name={aheadSVG}
                      className="circled"
                      fitted="1"
                      style={{ margin: 0 }}
                    />
                  </Button>
                </Grid.Column>
              </Grid>
            </Modal.Content>
          </Modal>
          ))}
        </Grid>

      </div>
    );
  }
};

export default View;
